import Validator from 'is_js'
import { isEmpty } from "lodash";
import { passwordValidationRegex } from '../../utils';


export function addAgency(data) {
    const { id = '' } = data

    let errors = {};
    const emailRegex = /^.+@.+\..+$/
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/

    if (Validator.empty(data.agency_name)) {
        errors.agency_name = "Agency Name is required"
    }

    if (Validator.empty(data.email)) {
        errors.email = "Email is required";
    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"
    }

    // if (!data?.postcode) {
    //     errors.postcode = "Postal code is required"
    // }

    // if (data?.postcode?.length && data?.postcode?.length < 3) {
    //     errors.postcode = "Valid Postal code is required"
    // }

    if (Validator.empty(data.contact_name)) {
        errors.contact_name = "Contact Name is required"
    }

    // if (Validator.empty(data.contact_last_name)) {
    //     errors.contact_last_name = "Contact Last Name is required"
    // }

    if (Validator.empty(data.contact) || data.contact === undefined) {
        errors.contact = "Contact Number is required";
    }

    if (data.contact && (data.contact.length < 8)) {
        errors.contact = "Contact Number must be 8 to 13 characters.";
    }

    if (data.contact && (data.contact.length > 13)) {
        errors.contact = "Contact Number must be 8 to 13 characters.";
    }

    if (!id) {
        if (!data.password) {
            errors.passwordText = "Password is required";
        }
        if (data.password) {
            let passwordRegError = passwordValidationRegex(data.password)
            if (!isEmpty(passwordRegError)) {
                errors.password = passwordRegError
            }
        }
    }

    if (id && data?.password) {
        let passwordRegError = passwordValidationRegex(data.password)
        if (!isEmpty(passwordRegError)) {
            errors.password = passwordRegError
        }
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}