import { isEmpty } from "lodash";
import { scrollIntoView } from "../../utils";

export function validateEditProperty(data) {
    let errors = {};
    let isError = false;

    const { registeredAddress = {} } = data
    // console.log("DATA in cumstoer validation page = = = = ", registeredAddress?.city)

    if (registeredAddress.address_1 === null && registeredAddress.address_2 === null) {
        if (!registeredAddress?.tower_block) {
            errors.tower_block = "Tower name is required"
            if (!isError) {
                isError = true;
                scrollIntoView("tower_block")
            }
        }
        if (!registeredAddress?.floor_no) {
            errors.floor_no = "Floor Number is required"
            if (!isError) {
                isError = true;
                scrollIntoView("floor_no")
            }
        }
        if (!registeredAddress?.flat_no) {
            errors.flat_no = "Flat number is required"
            if (!isError) {
                isError = true;
                scrollIntoView("flat_no")
            }
        }
        if (!registeredAddress?.building_name) {
            errors.building_name = "Building name is required"
            if (!isError) {
                isError = true;
                scrollIntoView("building_name")
            }
        }
    } else {
        if (!registeredAddress?.address_1) {
            errors.address_1 = "Address is required"
            if (!isError) {
                isError = true;
                scrollIntoView("address_1")
            }
        }
    }

    if (!registeredAddress?.property_name) {
        errors.property_name = "Property name is required"
        if (!isError) {
            isError = true;
            scrollIntoView("property_name")
        }
    }
    if (!registeredAddress?.city) {
        errors.city = "City is required"
        if (!isError) {
            isError = true;
            scrollIntoView("city")
        }
    }
    if (!registeredAddress?.state) {
        errors.state = "State is required"
        if (!isError) {
            isError = true;
            scrollIntoView("state")
        }
    }
    if (!registeredAddress?.zipcode) {
        errors.zipcode = "Zipcode is required"
        if (!isError) {
            isError = true;
            scrollIntoView("zipcode")
        }
    } else if (registeredAddress?.zipcode?.length < 6 || registeredAddress?.zipcode?.length > 6) {
        errors.zipcode = "Valid zipcode is required"
        if (!isError) {
            isError = true;
            scrollIntoView('zipcode')
        }
    }

    // if (!registeredAddress?.tariff_data?.tariff?.category_name) {
    //     errors.category_name = "Category name is required"
    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView("category_name")
    //     }
    // }

    // if (!timing?.start_time) {
    //     errors = {
    //         ...errors,
    //         [`start_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`]: "Start Time is Required"
    //     }
    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView(`start_tiem_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`)
    //     }
    // }

    return {
        isValid: isEmpty(errors),
        errors
    };
}


export function validateEditCustomer(data) {
    let errors = {};
    const emailRegex = /^.+@.+\..+$/

    if (!data.email) {
        errors.email = "Email is required";
    }

    if (data.email && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"
    }

    if (!data?.first_name) {
        errors.first_name = "First Name is required";
    }

    if (!data?.last_name) {
        errors.last_name = "Last Name is required";
    }

    if (!data?.phone_no) {
        errors.phone_no = "Phone is required";
    }

    if (data?.phone_no && data?.phone_no.length > 13) {
        errors.phone_no = "Mobile Number must be 8 to 13 characters";
    }

    if (data?.phone_no && data?.phone_no.length < 8) {
        errors.phone_no = "Mobile Number must be 8 to 13 characters";
    }

    return {
        isValid: isEmpty(errors),
        errors
    };

}
