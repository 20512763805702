import { isEmpty, max } from "lodash";
import { scrollIntoView } from "../../utils";
import { error } from "is_js";
import moment from 'moment';
import { differenceInHours } from 'date-fns';

export function tariff(data) {
    const { tariffs, company, stateName, tariff_type } = data || {}
    let errors = {}
    let isError = false;
    const addedSubCategories = new Set();
    if (!company) {
        errors = {
            ...errors,
            companyName: 'Company Name is required'
        }

        if (!isError) {
            isError = true;
            scrollIntoView('companyName')
        }
    }
    if (!stateName) {
        errors = {
            ...errors,
            state: 'State Name is required'
        }

        if (!isError) {
            isError = true;
            scrollIntoView('state')
        }
    }
    tariffs.forEach((tariffVal, tarifIdx) => {
        if (tariffVal?.is_unit_rate === "") {
            errors = {
                ...errors,
                [`tariff_type_${tarifIdx}`]: 'Type is required'
            }
            if (!isError) {
                isError = true;
                scrollIntoView(`tariff_type_${tarifIdx}`)
            }
        }
        if (tariffVal?.is_unit_rate && !tariffVal.unit_rate) {
            errors = {
                ...errors,
                [`unit_rate_${tarifIdx}`]: 'Flat Rate is required'
            }

            if (!isError) {
                isError = true;
                scrollIntoView(`unit_rate_${tarifIdx}`)
            }
        }
        else if (!tariffVal?.is_unit_rate && tariff_type === "Industrial") {
            tariffVal?.structure?.forEach(((tarifStruc, tarifStrucIdx) => {
                if (!tarifStruc?.category_name) {
                    errors = {
                        ...errors,
                        [`category_name_${tarifIdx}_${tarifStrucIdx}`]: 'Category Name is required'
                    }

                    if (!isError) {
                        isError = true;
                        scrollIntoView(`category_name_${tarifIdx}_${tarifStrucIdx}`)
                    }
                }
                if (!tarifStruc?.unit_rate_type) {
                    errors = {
                        ...errors,
                        [`unit_rate_type_${tarifIdx}_${tarifStrucIdx}`]: 'Fixed Charge is required'
                    }

                    if (!isError) {
                        isError = true;
                        scrollIntoView(`unit_rate_type_${tarifIdx}_${tarifStrucIdx}`)
                    }
                }
                if (!tarifStruc?.unit_rate) {
                    errors = {
                        ...errors,
                        [`unit_rate_${tarifIdx}_${tarifStrucIdx}`]: 'Unit Rate is required'
                    }
                    

                    if (!isError) {
                        isError = true;
                        scrollIntoView(`unit_rate_${tarifIdx}_${tarifStrucIdx}`)
                    }else {if(errors[`unit_rate_${tarifIdx}_${tarifStrucIdx}`]){
                        delete errors[`unit_rate_${tarifIdx}_${tarifStrucIdx}`]
                    }}
                }
                tarifStruc?.timings?.forEach(((timing, timingIdx) => {
                    if (!timing?.start_time) {
                        errors = {
                            ...errors,
                            [`start_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`]: "Start Time is Required"
                        }
                        if (!isError) {
                            isError = true;
                            scrollIntoView(`start_tiem_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`)
                        }
                    }
                    if (!timing?.end_time) {
                        errors = {
                            ...errors,
                            [`end_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`]: "End Time is Required"
                        }
                        if (!isError) {
                            isError = true;
                            scrollIntoView(`end_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`)
                        }
                    }

                    // if (timing?.end_time) {
                    //     let startTime = timing?.start_time;
                    //     let momentStartTime = moment(timing?.end_time)?.valueOf()
                    //     let momentEndTime = moment(startTime)?.valueOf()
                    //     let compareEndTime = moment(startTime)?.valueOf() > 0
                    //     if (momentStartTime < momentEndTime && compareEndTime) {
                    //         errors = {
                    //             ...errors,
                    //             [`end_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`]: "End time should be greater than start time!"
                    //         }
                    //         if (!isError) {
                    //             isError = true;
                    //             scrollIntoView(`end_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`)
                    //         }
                    //     }
                    // }
                    // if (timing?.start_time) {
                    //     let endTime = timing?.end_time;
                    //     let momentStartTime = moment(timing?.start_time)?.valueOf()
                    //     let momentEndTime = moment(endTime)?.valueOf()
                    //     let compareEndTime = moment(endTime)?.valueOf() > 0

                    //     if (momentStartTime > momentEndTime && compareEndTime) {
                    //         errors = {
                    //             ...errors,
                    //             [`start_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`]: "Start time should not be greater than end time!"
                    //         }
                    //         if (!isError) {
                    //             isError = true;
                    //             scrollIntoView(`start_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`)
                    //         }
                    //     }
                    // }
                }
                ))
            }))
            let finalStartTime = 0
            let totalMinutes = 0
            tariffVal?.structure?.forEach((tarifStruc, tarifStrucIdx) => {
                tarifStruc?.timings?.forEach((timing, timingIdx) => {
                    if ((timing?.start_time).valueOf() <= (timing?.end_time).valueOf()) {
                        let newEndTime  = timing?.end_time ? (moment(new Date()).set({hours:timing.end_time?.split(':')[0],minutes:timing.end_time?.split(':')[1],seconds:timing.end_time?.split(':')[2]}).format("YYYY-MM-DD HH:mm").valueOf()): null;
                        let startValue  = timing?.start_time ? moment(new Date()).set({hours:timing.start_time?.split(':')[0],minutes:timing.start_time?.split(':')[1],seconds:timing.start_time?.split(':')[2]}).format("YYYY-MM-DD HH:mm") : null;
                        const startTime = moment(startValue).valueOf() ;
                        const endTime = moment(newEndTime).valueOf() + 60000 ;
                        const dif = moment(endTime).diff(moment(startTime), 'minutes')
                        if (dif >= 1) totalMinutes = totalMinutes + dif
                    }

                    if (timing && timing.start_time && timing.end_time) {
                        const staticTimeEnd = moment().endOf('day').format('YYYY-MM-DD HH:mm').valueOf();
                        const staticTimeStart = moment().startOf('day').format('YYYY-MM-DD HH:mm').valueOf();
                        let startValue  = timing?.start_time ? moment(new Date()).set({hours:timing.start_time?.split(':')[0],minutes:timing.start_time?.split(':')[1],seconds:timing.start_time?.split(':')[2]}).format("YYYY-MM-DD HH:mm").valueOf() : null;
                        let endValue  = timing?.end_time ? moment(new Date()).set({hours:timing.end_time?.split(':')[0],minutes:timing.end_time?.split(':')[1],seconds:timing.end_time?.split(':')[2]}).format("YYYY-MM-DD HH:mm").valueOf()  : null;
                        const startTime = moment(startValue).valueOf() ;
                        const endTime = moment(endValue).valueOf() + 120000 ;
                        if (startTime > endTime) {
                            const newStartTime = moment(staticTimeEnd).diff(moment(startTime), 'minutes');
                            const newEndTime = moment(endTime).diff(moment(staticTimeStart), 'minutes');
                            if(newStartTime  >=1 ) finalStartTime =  finalStartTime + (newStartTime + newEndTime)
                          }
                      }
                    if (timing?.start_time && timing?.end_time) {
                        let endTime = moment(timing?.end_time)?.valueOf()
                        let startTime = moment(timing?.start_time)?.valueOf()
                        if (endTime === startTime) {
                            errors = {
                                ...errors,
                                [`end_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`]: "Start time & End time can not be Same!"
                            }
                            if (!isError) {
                                isError = true;
                                scrollIntoView(`end_time_${tarifIdx}_${tarifStrucIdx}_${timingIdx}`)
                            }
                        }
                    }
                })
            })
            if (totalMinutes + finalStartTime !== 1440) {
                errors = {
                    ...errors,
                    [`error_hour_${tarifIdx}`]: 'Total hour should be 24 hours!'
                }

                if (!isError) {
                    isError = true;
                    scrollIntoView(`error_hour_${tarifIdx}`)
                }
            }
        }

        else if (!tariffVal?.is_unit_rate && tariff_type === "Domestic") {
            tariffVal?.structure?.forEach(((tarifStruc, tarifStrucIdx) => {
                if (!tarifStruc?.category_name) {
                    errors = {
                        ...errors,
                        [`category_name_${tarifIdx}_${tarifStrucIdx}`]: 'Category Name is required'
                    }

                    if (!isError) {
                        isError = true;
                        scrollIntoView(`category_name_${tarifIdx}_${tarifStrucIdx}`)
                    }
                }
                // if (!tarifStruc?.unit_rate_type) {
                //     errors = {
                //         ...errors,
                //         [`unit_rate_type_${tarifIdx}_${tarifStrucIdx}`]: 'Fixed Charge is required'
                //     }

                //     if (!isError) {
                //         isError = true;
                //         scrollIntoView(`unit_rate_type_${tarifIdx}_${tarifStrucIdx}`)
                //     }
                // }
                if (!tarifStruc?.fixed_charge) {
                    errors = {
                        ...errors,
                        [`fixed_charge_${tarifIdx}_${tarifStrucIdx}`]: 'Fixed Charge is required'
                    }

                    if (!isError) {
                        isError = true;
                        scrollIntoView(`fixed_charge_${tarifIdx}_${tarifStrucIdx}`)
                    }
                }
                // if (!tarifStruc?.unit_rate) {
                //     errors = {
                //         ...errors,
                //         [`unit_rate_${tarifIdx}_${tarifStrucIdx}`]: 'Unit Rate is required'
                //     }

                //     if (!isError) {
                //         isError = true;
                //         scrollIntoView(`unit_rate_${tarifIdx}_${tarifStrucIdx}`)
                //     }
                // }
                tarifStruc?.rates?.forEach(((rate, rateIdx) => {
                    if (!rate?.sub_category) {
                        errors = {
                            ...errors,
                            [`sub_category_${tarifIdx}_${tarifStrucIdx}_${rateIdx}`]: 'Sub Category is required'
                        }
                        
                        if (!isError) {
                            isError = true;
                            scrollIntoView(`sub_category_${tarifIdx}_${tarifStrucIdx}_${rateIdx}`)
                        }
                    } 
                    // else {
                    //     if ( addedSubCategories.has(rate.sub_category.trim())  ) {
                    //         errors = {
                    //             ...errors,
                    //             [`sub_category_${tarifIdx}_${tarifStrucIdx}_${rateIdx}`]: 'Sub Category has already been added'
                    //         }
                            
                    //         if (!isError) {
                    //             isError = true;
                    //             scrollIntoView(`sub_category_${tarifIdx}_${tarifStrucIdx}_${rateIdx}`)
                    //         }
                    //     } else {
                    //         addedSubCategories.add(rate.sub_category);
                    //     }
                    // }


                    if (!rate?.unit_rate) {
                        errors = {
                            ...errors,
                            [`unit_rate_${tarifIdx}_${tarifStrucIdx}_${rateIdx}`]: 'Unit Rate is required'
                        }

                        if (!isError) {
                            isError = true;
                            scrollIntoView(`unit_rate_${tarifIdx}_${tarifStrucIdx}_${rateIdx}`)
                        }
                    }

                }
                ))
            }))
        }
    })
    return {
        isValid: isEmpty(errors),
        errors
    }
}