import { apiDelete, apiGet, apiPost, apiPut, getDeviceType } from '../../../utils';


export function getAllAgentsAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }

    return apiGet(`/api/agent/get/all?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}

export function getInstallerAgentAPI(id) {
    return apiGet(`/api/agent/get/${id}?deviceType=${getDeviceType()}`)
}

export function updateInstallerAgentAPI(agentData, id) {
    let finalData;
    if(agentData?.password && agentData?.password.length){
        finalData = {
            first_name: agentData.first_name,
            last_name: agentData.last_name,
            email: agentData.email,
            phone: agentData.phone,
            status: agentData.status,
            password : agentData.password,
            agency_id : agentData.agency_id,
            deviceType: getDeviceType()
        }
    }else{
        finalData = {
            first_name: agentData.first_name,
            last_name: agentData.last_name,
            email: agentData.email,
            phone: agentData.phone,
            status: agentData.status,
            agency_id : agentData.agency_id,
            deviceType: getDeviceType()
        }
    }
    return apiPut(`/api/agent/edit/${id}`, finalData)
}

export function getAgentDeviceInfoAPI(id) {
    return apiGet(`/api/device/get-info?device_id=${id}&deviceType=${getDeviceType()}`)
}

export function getAgentDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/agent/get-devices?agent_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function agentLoginPayloadForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/agent/login-payload?agent_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function DownloadCSVAllAgentsAPI() {
    return apiGet(`/api/export/agents?&deviceType=${getDeviceType()}`)
}

// export function getAgentRatedByCustomerForTableAPI(filters) {
//     let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '', rating = '' } = filters || {}
//     let obj = {
//         installer_id: id,
//         rating
//     }
//     return apiGet(`/api/agent/agent-rating-customers?page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`,obj)
// }

export function createAgentAPI(data) {
    // let flow = data?.flows ? data?.flows?.filter(res => res) : []

    const formData = new FormData()
    formData.append("first_name", data?.first_name)
    formData.append("last_name", data?.last_name)
    formData.append("email", data?.email)
    formData.append("phone", data?.phone)
    formData.append("password", data?.password)
    formData.append("agency_id", data?.agency_id)
    formData.append("status", data?.status === "active" ? "active" : "inactive")
    formData.append("deviceType", getDeviceType())

    return apiPost('/api/agent/add', formData)
}

export function deleteAgentAPI(id) {
    return apiDelete(`/api/agent/delete?installer_id=${id}`)
}