import { isEmpty } from "lodash";

export function addSite(data) {
    let errors = {}

    if (!data?.site_name) {
        errors.site_name = 'Site Name is required.'
    }
    if (!data?.contact_name) {
        errors.contact_name = 'Contact Name is required.'
    }

    if (!data?.contact_email) {
        errors.contact_email = 'Contact Email is required.'
    }
    if (!data?.contact_no) {
        errors.contact_no = 'Contact Number is required.'
    }
    if (!data?.city) {
        errors.city = 'City is required.'
    }
    if (!data?.state) {
        errors.state = 'State is required.'
    }

    if (!data?.zipcode) {
        errors.zipcode = 'Zipcode is required.'
    }
    if (!data?.latitude) {
        errors.latitude = 'latitude is required.'
    }
    if (!data?.longitude) {
        errors.longitude = 'longitude is required.'
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}