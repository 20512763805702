import { apiGet, apiPost, apiPut, getDeviceType } from '../../../utils';
import moment from 'moment';


export function getAllLivelinkDevicesAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "customer_status","connection_type"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/livelink/list?filter=${JSON.stringify(arr)}`)
}


export function getLivelinkDeviceByIdAPI(id) {
    return apiGet(`/api/livelink/info/${id}`)
}

export function getLivelinkDeviceHistoryBySerialNoAPI(serialNo) {
    return apiGet(`/api/livelink/install-history?serial_number=${serialNo}`)
}