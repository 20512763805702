import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link, useHistory, useRouteMatch } from 'react-router-dom'
import * as actions from '../../actions'
import EnhancedTableHead from "../../../../../components/common/EnhancedTableHead";
import EditIcon from '@material-ui/icons/Edit';
import { withTranslation } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { tablestyle, dateWithTime, propertyAddressFormat, dateWithoutTime, capitalizeFirstLetter } from '../../../../../utils'
import TableLoader from '../../../../../components/common/TableLoader';
import CustomTablePagination from '../../../../../components/common/CustomPagination';
import _ from "lodash"
import * as commonActions from '../../../../common/actions'

import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreText from '../../../../../components/common/TextMore';

const manageHead = [
    { id: 'serial_number', sortByKey: 'serial_number', label: "Serial Number", className: "show-pointer" },
    { id: 'connection_type', sortByKey: 'connection_type', label: "Connection Type", className: "show-pointer" },
    { id: 'installation_date', sortByKey: 'installation_date', label: "Date Installed", className: "show-pointer" },
    { id: 'device_serial_number', sortByKey: 'device_serial_number', label: "Electricity Advisor Serial Number", className: "show-pointer" },
    // { id: 'meter_phase_type', sortByKey: 'meter_phase_type', label: "Meter Phase Type", className: "show-pointer" },
    { id: "soft_version", sortByKey: 'soft_version', label: "Firmware Version", className: "show-pointer" },
    { id: "status", sortByKey: 'status', label: "Device Status", className: "show-pointer" },
    { id: 'id', sortByKey: 'id', label: "Record ID", className: "show-pointer" },
    // { id: 'livelink_name', sortByKey: 'livelink_name', label: "Livelink Name", className: "show-pointer" },
    // { id: 'customer_status', sortByKey: 'customer_status', label: "Customer State", className: "show-pointer" },
    // { id: 'status', sortByKey: 'status', label: "Device Status", className: "show-pointer" },
    // { id: 'action', sortByKey: 'action', label: "Action", stickyClassName: "more" },

    { id: "action", sortByKey: 'action', label: "Action", className: "show-pointer", stickyClassName: "more" },
];


function SupergatewayDevice(props) {
    const { classes, } = props
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const routeMatch = useRouteMatch();

    const [filters, setfilters] = useState({ limit: 25, page: 1, sortBy: 'created_at', orderBy: 'desc' })
    const [refresh, setRefresh] = useState(false);
    const [goToPage, setGoToPage] = useState('')
    const delayGoToPagination = useCallback(_.debounce(filters => goToPageFun(filters), 1000), []);

    const { cusPropertyDevicesForTable = {}, cusPropertyDevicesIsLoading = false } = useSelector(state => state.customer)
    const { data: propertyDevicesList = [], total = 0 } = cusPropertyDevicesForTable

    useEffect(() => {
        refreshDevices(filters)
        // return () => {
        //     dispatch(actions.clearStorePropertyDevice())
        // }
    }, [])

    const refreshDevices = (filters) => {
        filters = { ...filters, id: params.id }
        dispatch(actions.getCusPropertyDevicesForTable(filters, "supergateway"))
    }

    const goToPageFun = (value) => {
        refreshDevices(value)
        // setNewFilters({ ...filters, page: parseInt(value) })
    }

    const onClickRefresh = () => {
        setRefresh(true)
        let newFilters = filters
        newFilters = { ...newFilters, id: params.id }
        dispatch(actions.getCusPropertyDevicesForTable(newFilters, "supergateway")).then(() => setRefresh(false)).catch(err => setRefresh(false))
    }

    const onPageChange = (e, page) => {
        refreshDevices({ ...filters, page })
        setfilters({ ...filters, page })
    }

    const onChangeLimit = (e) => {
        refreshDevices({ ...filters, limit: e.target.value, page: 1, sortBy: 'created_at', orderBy: 'desc', })
        setfilters({ ...filters, limit: e.target.value, page: 1, })
        setGoToPage('')
    }

    const onSortBy = (label) => {
        if (label === "action" || label === "installer_uuid") return
        let orderBy = ''
        if (filters.orderBy === 'desc') {
            orderBy = 'asc'
        } else {
            orderBy = 'desc'
        }
        const newFilters = { ...filters, sortBy: label, orderBy: orderBy }
        setfilters(newFilters)
        refreshDevices(newFilters)
    }

    const onChangeGoToPage = ({ target: { value = '' } }) => {
        if (!value.match(/^[0-9]*$/) || value === "0") return
        let pageValue = Math.ceil(total / filters.limit)
        if (value > pageValue) return
        setGoToPage(value ? parseInt(value) : '')
        setfilters({ ...filters, page: value ? parseInt(value) : '' })
        delayGoToPagination({ ...filters, page: value })
    }

    const goToDeviceDetailPage = (id) => {
        dispatch(commonActions.changeSidebarProduct("supergateway")).then(res => {
            setTimeout(() => {
                history.push({
                    pathname: `/admin/supergateway/devices/view/${id}`,
                    state: {
                        prevPath: routeMatch.path,
                        prevUrl: routeMatch.url
                    }
                })
            }, 1)
        })
    }

    return (
        <div>
            {/* <Grid item xs={12} sm={12} lg={12} md={12}>
                <h2 >Devices</h2>
            </Grid> */}
            <Grid item xs={12} sm={12} lg={12} md={12}>
                <div id="no-more-tables" className="card-design table-design cf" >
                    {
                        cusPropertyDevicesIsLoading ?
                            <>
                                <TableContainer className={classes.container} style={{ overflow: "hidden" }}>
                                    <Table className="table-program" stickyHeader aria-label="sticky table">
                                        <EnhancedTableHead
                                            headCells={manageHead}
                                        />
                                    </Table>
                                </TableContainer>
                                <TableLoader />
                            </>
                            : null
                    }

                    {
                        !cusPropertyDevicesIsLoading ? <TableContainer className={classes.container} >
                            <Table className="table-program" stickyHeader aria-label="sticky table">
                                <EnhancedTableHead
                                    headCells={manageHead}
                                    onSortBy={onSortBy}
                                    filters={filters}
                                />
                                <TableBody>
                                    {
                                        propertyDevicesList && propertyDevicesList.length ? propertyDevicesList.map((item, index) => {
                                            let { serial_number = '', property_id = '', created_at = '', customer_status = '', id = '', status = '', ihd_serial_number = '', connection_type = '', livelink_name = '', installation_date = '' , device_serial_number =  '', soft_version = '', gateway_conn_id = ''} = item

                                            let statusCSS = customer_status === "Completed" ? "green" : customer_status === "Incomplete" ? "blue" : customer_status === "Pending" ? "yellow" : customer_status === "Deleted" ? "red" : ''


                                            let devicestatusCSS = status === "active" ? "green brands-title" : status === "in-active" ? "red" : ""

                                            if (status == "in-active") {
                                                status = "Inactive"
                                                customer_status = ""
                                                statusCSS = ''
                                            }

                                            return <TableRow key={index} hover role="checkbox" className="cursor_default">
                                                <TableCell className="table-custom-width">{serial_number || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{connection_type || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{installation_date || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{device_serial_number || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{soft_version || "-"}</TableCell>
                                                <TableCell className={`table-custom-width ${devicestatusCSS}`}>{capitalizeFirstLetter(status) || "-"}</TableCell>
                                                <TableCell  >
                                                    <MoreText data={id} />
                                                </TableCell>
                                                {/* <TableCell className="table-custom-width">{dateWithoutTime(created_at) || "-"}</TableCell>
                                                <TableCell className={`table-custom-width ${statusCSS}`}>{customer_status || "-"}</TableCell> */}
                                                <TableCell onClick={() => goToDeviceDetailPage(gateway_conn_id || id)} className="table-custom-width more">
                                                    {/* <Link to={`/admin/livelink/devices/view/${id}`}> */}
                                                    <VisibilityIcon className="show-pointer" />
                                                    {/* </Link> */}
                                                </TableCell>

                                            </TableRow>
                                        })
                                            :
                                            <TableRow>
                                                <TableCell style={{ border: "none" }} colSpan="10" className="table-no-data">No Data Found</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer> : null
                    }
                    <CustomTablePagination
                        count={total}
                        refresh={refresh}
                        onClickRefresh={onClickRefresh}
                        filters={filters}
                        onPageChange={onPageChange}
                        onChangeLimit={onChangeLimit}
                        onChangeGoToPage={onChangeGoToPage}
                        goToPage={goToPage}
                    />
                </div>
            </Grid>
        </div>
    )
}

export default withTranslation("translations")(withStyles(tablestyle)(SupergatewayDevice));
