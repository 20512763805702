import { apiGet, apiPost, apiPut, getObject, getDeviceType, apiDelete } from '../../../utils';
import { REACT_APP_FLASHES_SERVER_LESS_API_BASE_URL } from '../../../constant'
import moment from 'moment';
import axios from 'axios';


export function getAllDevicesAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/device/get/all?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)

}

export function getAllSitesAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/site/get?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)

}

export function addSiteAPI(data) {
    return apiPut(`/api/site/update`,data)
}


export function getSingleSiteAPI(id) {
    return apiGet(`/api/site/get/${id}`,null)
}




export function getDeviceByIdAPI(id) {
    return apiGet(`/api/device/get-info?device_id=${id}&deviceType=${getDeviceType()}`)
}


export function updateDeviceDetailAPI(data) {

    let { deviceData = {}, id = '' } = { ...data }

    if (deviceData?.impulse_rate_type == "Wh/imp") {
        deviceData.impulse_rate = 1000 / deviceData?.impulse_rate
    }

    if (deviceData?.force_sync_date !== null) {
        deviceData = { ...deviceData, force_sync_date: moment(deviceData.force_sync_date).format("YYYY-MM-DD") }
    }

    return apiPut(`/api/device/edit/${id}`, deviceData)
}

export function updateDeviceInstallationDateAPI(data) {
    const { id = '', deviceData = {} } = data
    const { created_at = '' } = deviceData
    let obj = {
        new_date: moment(created_at).format("YYYY-MM-DD"),
        device_id: id,
    }
    return apiPut('/api/device/update/created-date', obj)
}

export function getImpulseRateHistoryAPI(id) {
    return apiGet(`/api/device/impulse-rate?device_id=${id}`)
}

export function getFirmwareHistoryAPI(id) {
    return apiGet(`/api/device/ota-history?device_id=${id}&deviceType=${getDeviceType()}`)
}

export function getDeviceHistoryBySerialNoAPI(serialNo) {
    return apiGet(`/api/device/install-history?serial_number=${serialNo}`)
}

// export function DownloadCSVAllDevicesAPI() {
//     return apiGet('/api/export/devices')
// }

export function updateDeviceHistoryTariffRatesAPI(data) {
    let { tariffDetail, impuseRate, date, discountSameAsCurrent, id, updateSection } = data

    let tariff_data = {
        ...tariffDetail,
        gst_include: tariffDetail.gstIncluded === true ? 1 : 0,
        discount_percentage: tariffDetail?.discount_percentage ? tariffDetail?.discount_percentage : 0,
        supply_charge: tariffDetail?.supply_charge ? tariffDetail?.supply_charge : 0,
        supply_charge_discount: tariffDetail?.supply_charge_discount ? 1 : 0,
        unit_charge: tariffDetail?.is_flat_rate ? tariffDetail?.unit_charge : 0,
        start_date: moment(date.startDate).format("YYYY-MM-DD"),
        end_date: moment(date.endDate).format("YYYY-MM-DD"),
    }

    if (impuseRate?.impulse_rate_type == "Wh/imp") {
        impuseRate.impulse_rate = 1000 / impuseRate?.impulse_rate
    }

    let device_data = {
        start_date: moment(date.startDate).format("YYYY-MM-DD"),
        end_date: moment(date.endDate).format("YYYY-MM-DD"),
        rate: impuseRate.impulse_rate,
        type: impuseRate.impulse_rate_type
    }

    let obj = {}

    if (updateSection === "impulseRate") {
        obj = { ...obj, device_data: { ...device_data }, tariff_data: {} }
    } else if (updateSection === "tariffRate") {
        obj = { ...obj, tariff_data: { ...tariff_data }, device_data: {} }
    } else if (updateSection === "both") {
        obj = { ...obj, device_data: { ...device_data }, tariff_data: { ...tariff_data } }
    } else return

    return apiPut(`/api/device/correction/${id}`, obj)
}

export function downloadCSVDeviceFlashAPI(date, id) {
    let userToken = getObject("access-token");
    let { term = '', endDate = '' } = date
    if (term) term = moment(date.term).format("YYYY-MM-DD")
    if (endDate) endDate = moment(date.endDate).format("YYYY-MM-DD")

    return new Promise(async (resolve, reject) => {
        try {
            let data = await axios({
                method: 'get',
                baseURL: REACT_APP_FLASHES_SERVER_LESS_API_BASE_URL,
                url: '/device/flashes-csv',
                params: {
                    device_id: id,
                    start_date: term,
                    end_date: endDate
                },
                headers: {
                    "Authorization": `Bearer ${(userToken) || null}`
                },
            })
            return resolve(data)

        } catch (e) {
            return reject(e)
        }
    })
}

export function deleteSiteAPI(id,deviceType) {
    let obj = {
        job_id: id,
        "deviceType": getDeviceType(deviceType)
    }
    return apiDelete(`/api/site/delete/${id}`)
}
