import { apiGet, apiPut, getDeviceType } from '../../../utils';


export function getAllOTAAPI(filters = {}) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen" || key === 'deviceType') continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status", "upgradable","force_update"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/ota/devices?filter=${JSON.stringify(arr)}&deviceType=${filters?.deviceType || getDeviceType()}`)
}

export function updateDeviceFirmwareAPI(data, deviceType) {
    let { selected = [], allFirmwareTargetVersion = '', allSelected = false, allUpgradeTarget = false, allForceUpgradeTarget = false } = data
    let allUpgradable = {}
    if (allSelected) {
        allUpgradable = {
            upgradable: allUpgradeTarget,
            force_update: allForceUpgradeTarget,
            available_firmware: allFirmwareTargetVersion
        }
    }

    selected = selected?.length ? selected.map(res => ({ ...res, upgradable: allUpgradeTarget, force_update: allForceUpgradeTarget })) : []
    selected = selected?.length ? selected.filter(item => item.available_firmware) : []

    let obj = {
        devices: allSelected ? [] : selected,
        all: allUpgradable,
        deviceType: deviceType
    }
    // return apiPut(allSelected ? 'https://stage.api.sync.flashdata.ihd.eems.app/ota/update-firmware' : '/api/ota/update-firmware', obj)
    return apiPut( '/api/ota/update-firmware', obj)
}

export function singleDeviceUpdateFirmwareAPI(data, deviceType) {
    let allUpgradable = {}
    let obj = {
        devices: data,
        all: allUpgradable,
        deviceType: deviceType

    }
    return apiPut('/api/ota/update-firmware', obj)
}
