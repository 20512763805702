import { apiGet, apiPost, apiPut, apiDelete, getDeviceType } from '../../../utils';


export function getAllAgenciesAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }

    return apiGet(`/api/agency/get/all?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)

}

export function getAgencyAPI(id) {
    return apiGet(`/api/agency/get/${id}?deviceType=${getDeviceType()}`)
}

export function updateAgencyAPI(data, id) {
    const formData = new FormData()

    if (data.password && data.password.length) {
        formData.append("password", data.password)
    }
    // let flow = data?.flows ? data?.flows?.filter(res => res) : []


    formData.append("agency_name", data?.agency_name)
    formData.append("contact_name", data?.contact_name)
    formData.append("contact_last_name", data?.contact_last_name)
    formData.append("email", data?.email)
    formData.append("postcode", data?.postcode)
    formData.append("contact", data?.contact)
    formData.append("country", data?.country)
    formData.append("state", ((data?.state?.value) && (data?.state?.value !== null) || (data?.state?.value !== undefined)) ? data?.state?.value : "")
    formData.append("city", data?.city)
    formData.append("street_address", data?.street_address)
    formData.append("plot_address", data?.plot_address)
    formData.append("status", data?.status)
    // formData.append("flows", flow.join(","))
    formData.append("deviceType", getDeviceType())

    return apiPost(`/api/agency/edit/${id}`, formData)
}

export function createAgencyAPI(data) {
    // let flow = data?.flows ? data?.flows?.filter(res => res) : []

    const formData = new FormData()
    formData.append("agency_name", data?.agency_name)
    formData.append("contact_name", data?.contact_name)
    formData.append("contact_last_name", data?.contact_last_name)
    formData.append("email", data?.email)
    formData.append("postcode", data?.postcode)
    formData.append("contact", data?.contact)
    formData.append("country", data?.country)
    formData.append("state", ((data?.state?.value) && (data?.state?.value !== null) || (data?.state?.value !== undefined)) ? data?.state?.value : "")
    formData.append("city", data?.city)
    formData.append("street_address", data?.street_address)
    formData.append("plot_address", data?.plot_address)
    formData.append("status", data?.status === "Active" ? "Active" : "In-Active")
    formData.append("password", data?.password)
    // formData.append("flows", flow.join(","))
    formData.append("deviceType", getDeviceType())

    return apiPost('/api/agency/add', formData)
}


export function deleteAgencyAPI(id) {
    return apiDelete(`/api/agency/delete/${id}`)
}

export function getAgencyDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/agency/get-devices?agency_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function getAgencyAgentForTableAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue
        if (key === "id") continue
        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/agency/get-agents?agency_id=${filters.id}&filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}


export function downloadCSVAllAgenciesAPI() {
    return apiGet(`/api/export/agencies?&deviceType=${getDeviceType()}`)
}

// export function getAgencyRatedByCustomerForTableAPI(filters) {
//     let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '', rating = '' } = filters || {}
//     let obj = {
//         agency_id: id,
//         rating
//     }
//     return apiGet(`/api/agency/rating-customers?page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`, obj)
// }