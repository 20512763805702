import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allJobs: {},
    job: {},
    installationDateIsLoading: false,
    impulseRateHistory: {},
    firmwareHistory: {},
    deviceHistory: [],
    isSearching: false,
    singleCSVDataForTable: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_JOBS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_JOBS_SUCCESS:
            return { ...state, isLoading: false, allJobs: action.payload || {} }

        case actionsTypes.GET_ALL_JOBS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DEVICE_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DEVICE_BY_ID_SUCCESS:
            return { ...state, isLoading: false, device: action.payload || {} }

        case actionsTypes.GET_DEVICE_BY_ID_FAILED:
            return { ...state, isLoading: false }


        case actionsTypes.UPDATE_DEVICE_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_DEVICE_BY_ID_SUCCESS:
            return { ...state, isLoading: false, }

        case actionsTypes.UPDATE_DEVICE_BY_ID_FAILED:
            return { ...state, isLoading: false }

        // job reducers


        case actionsTypes.ADD_JOB_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ADD_JOB_SUCCESS:
            return { ...state, isLoading: false, }

        case actionsTypes.ADD_JOB_FAILED:
            return { ...state, isLoading: false }


        case actionsTypes.SEARCH_DATA_REQUEST:
            return { ...state, isSearching: true }

        case actionsTypes.SEARCH_DATA_SUCCESS:
            return { ...state, isSearching: false, }

        case actionsTypes.SEARCH_DATA_FAILED:
            return { ...state, isSearching: false }


        case actionsTypes.GET_SINGLE_JOB_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_SINGLE_JOB_SUCCESS:
            return { ...state, isLoading: false, job: action?.payload || {} }

        case actionsTypes.GET_SINGLE_JOB_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_CSV_DATA_FOR_TABLE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_CSV_DATA_FOR_TABLE_SUCCESS:
            return { ...state, isLoading: false,singleCSVDataForTable:action?.payload || {} }

        case actionsTypes.GET_CSV_DATA_FOR_TABLE_FAILED:
            return { ...state, isLoading: false }

        // end job reducers


        case actionsTypes.UPDATE_DEVICE_INSTALLATION_DATE_REQUEST:
            return { ...state, installationDateIsLoading: true }

        case actionsTypes.UPDATE_DEVICE_INSTALLATION_DATE_SUCCESS:
            return { ...state, installationDateIsLoading: false, }

        case actionsTypes.UPDATE_DEVICE_INSTALLATION_DATE_FAILED:
            return { ...state, installationDateIsLoading: false }

        case actionsTypes.GET_IMPULSE_RATE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_IMPULSE_RATE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, impulseRateHistory: action.payload }

        case actionsTypes.GET_IMPULSE_RATE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_FIRMWARE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_FIRMWARE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, firmwareHistory: action.payload }

        case actionsTypes.GET_FIRMWARE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DEVICE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DEVICE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, deviceHistory: action.payload }

        case actionsTypes.GET_DEVICE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_DEVICE_DATA:
            return { ...state, ...initialState }

        default:
            return state
    }
}
