import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allTariffs: {},
    message: '',
    distributorCompanyList: [],
    singleTarrif: {},
    tariffStructureList: [],
    categoryList:[]
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_TARIFFS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_TARIFFS_SUCCESS:
            return { ...state, isLoading: false, allTariffs: action.payload || {} }

        case actionsTypes.GET_ALL_TARIFFS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_TARRIF_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ADD_TARRIF_SUCCESS:
            return { ...state, isLoading: false, message: action.payload.message }

        case actionsTypes.ADD_TARRIF_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_TARRIF_DETAIL_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_TARRIF_DETAIL_SUCCESS:
            return { ...state, isLoading: false, singleTarrif: action.payload && action.payload.info }

        case actionsTypes.GET_TARRIF_DETAIL_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_TARRIF_DETAIL_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_TARRIF_DETAIL_SUCCESS:
            return { ...state, isLoading: false, }

        case actionsTypes.UPDATE_TARRIF_DETAIL_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_TARRIF_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_TARRIF_SUCCESS:
            return { ...state, isLoading: false, }

        case actionsTypes.DELETE_TARRIF_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_TARIFF_STRUCTURE_LIST_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_TARIFF_STRUCTURE_LIST_SUCCESS:
            return { ...state, isLoading: false, tariffStructureList: action.payload || [] }


        case actionsTypes.GET_CATEGORY_LIST_SUCCESS:
            return { ...state, isLoading: false,  categoryList: action.payload?.categories || [] }
            

        case actionsTypes.GET_TARIFF_STRUCTURE_LIST_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DISTRIBUTOR_COMPANY_LIST_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DISTRIBUTOR_COMPANY_LIST_SUCCESS:
            return { ...state, isLoading: false, distributorCompanyList: action.payload || [] }

        case actionsTypes.GET_DISTRIBUTOR_COMPANY_LIST_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DISTRIBUTOR_ACCORDING_POSTCODE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DISTRIBUTOR_ACCORDING_POSTCODE_SUCCESS: {
            if (action.payload.status) {
                let { info = [] } = action.payload
                return { ...state, isLoading: false, distributorCompanyList: info?.length ? info?.map(val => ({ title: val.company_name, value: val.id })) : [] }
            }
            else {
                return { ...state, isLoading: false }
            }
        }

        case actionsTypes.GET_DISTRIBUTOR_ACCORDING_POSTCODE_FAILED: {
            if (!action?.payload?.status) {
                let { info = [] } = action.payload
                return { ...state, isLoading: false, distributorCompanyList: info?.length ? info?.map(val => ({ title: val.company_name, value: val.id })) : [] }
            }
            else {
                return { ...state, isLoading: false }
            }
        }

        case actionsTypes.CLEAR_ALL_TARIFF_DATA: {
            return { ...state, ...initialState }
        }



        default:
            return state 
    }
}