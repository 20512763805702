import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {
    getAllDevicesAPI,
    getDeviceByIdAPI, updateDeviceDetailAPI,
    updateDeviceInstallationDateAPI,
    getImpulseRateHistoryAPI,
    getFirmwareHistoryAPI,
    getDeviceHistoryBySerialNoAPI,
    downloadCSVDeviceFlashAPI,
    updateDeviceHistoryTariffRatesAPI,
    getAllJobsAPI,
    addJobAPI,
    searchSiteAPI,
    getSingleJobAPI,
    getCSVDataForSingleJobDetailAPI,
    deleteJobAPI,
    updateJobAPI
} from './apis'
import { DownloadCSVAllDevicesAPI } from "../../common/apis";

export const DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAILED = 'DELETE_JOB_FAILED';

export const deleteJob = (id, deviceType) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_JOB_REQUEST
        })
        deleteJobAPI(id, deviceType).then(res => {
            dispatch({
                type: DELETE_JOB_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_JOB_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAILED = 'UPDATE_JOB_FAILED';

export const updateJob = (id, data) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_JOB_REQUEST
        })
        updateJobAPI(id, data).then(res => {
            dispatch({
                type: UPDATE_JOB_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_JOB_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_ALL_JOBS_REQUEST = 'GET_ALL_JOBS_REQUEST';
export const GET_ALL_JOBS_SUCCESS = 'GET_ALL_JOBS_SUCCESS';
export const GET_ALL_JOBS_FAILED = 'GET_ALL_JOBS_FAILED';

export const getAllJobs = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_JOBS_REQUEST
        })
        getAllJobsAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_JOBS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_JOBS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_SINGLE_JOB_REQUEST = 'GET_SINGLE_JOB_REQUEST';
export const GET_SINGLE_JOB_SUCCESS = 'GET_SINGLE_JOB_SUCCESS';
export const GET_SINGLE_JOB_FAILED = 'GET_SINGLE_JOB_FAILED';

export const getSingleJob = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SINGLE_JOB_REQUEST
        })
        getSingleJobAPI(id).then(res => {
            dispatch({
                type: GET_SINGLE_JOB_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SINGLE_JOB_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const ADD_JOB_REQUEST = 'ADD_JOB_REQUEST';
export const ADD_JOB_SUCCESS = 'ADD_JOB_SUCCESS';
export const ADD_JOB_FAILED = 'ADD_JOB_FAILED';

export const addJob = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: ADD_JOB_REQUEST
        })
        addJobAPI(data).then(res => {
            dispatch({
                type: ADD_JOB_SUCCESS,
                // payload: res && res.info
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: ADD_JOB_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const SEARCH_DATA_REQUEST = 'SEARCH_DATA_REQUEST';
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS';
export const SEARCH_DATA_FAILED = 'SEARCH_DATA_FAILED';

export const searchDataListings = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: SEARCH_DATA_REQUEST
        })
        searchSiteAPI(data).then(res => {
            dispatch({
                type: SEARCH_DATA_SUCCESS,
                // payload: res && res.info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: SEARCH_DATA_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_CSV_DATA_FOR_TABLE_REQUEST = 'GET_CSV_DATA_FOR_TABLE_REQUEST';
export const GET_CSV_DATA_FOR_TABLE_SUCCESS = 'GET_CSV_DATA_FOR_TABLE_SUCCESS';
export const GET_CSV_DATA_FOR_TABLE_FAILED = 'GET_CSV_DATA_FOR_TABLE_FAILED';

export const getCSVDataForSingleJobDetail = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_CSV_DATA_FOR_TABLE_REQUEST
        })
        getCSVDataForSingleJobDetailAPI(data).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_CSV_DATA_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CSV_DATA_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}




export const GET_ALL_DEVIECS_REQUEST = 'GET_ALL_DEVIECS_REQUEST';
export const GET_ALL_DEVIECS_SUCCESS = 'GET_ALL_DEVIECS_SUCCESS';
export const GET_ALL_DEVIECS_FAILED = 'GET_ALL_DEVIECS_FAILED';

export const getAllDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_DEVIECS_REQUEST
        })
        getAllDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const uploadSiteCSV = (data) => dispatch => {
    dispatch({
        type: GET_ALL_DEVIECS_REQUEST
    })
    dispatch({
        type: GET_ALL_DEVIECS_SUCCESS,
        payload: { data, total: data?.length || 0 } || {}
    })
}


export const GET_DEVICE_BY_ID_REQUEST = 'GET_DEVICE_BY_ID_REQUEST';
export const GET_DEVICE_BY_ID_SUCCESS = 'GET_DEVICE_BY_ID_SUCCESS';
export const GET_DEVICE_BY_ID_FAILED = 'GET_DEVICE_BY_ID_FAILED';

export const getDeviceById = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DEVICE_BY_ID_REQUEST
        })
        getDeviceByIdAPI(id).then(res => {
            dispatch({
                type: GET_DEVICE_BY_ID_SUCCESS,
                payload: res && res.info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DEVICE_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const UPDATE_DEVICE_BY_ID_REQUEST = 'UPDATE_DEVICE_BY_ID_REQUEST';
export const UPDATE_DEVICE_BY_ID_SUCCESS = 'UPDATE_DEVICE_BY_ID_SUCCESS';
export const UPDATE_DEVICE_BY_ID_FAILED = 'UPDATE_DEVICE_BY_ID_FAILED';

export const updateDeviceDetail = (data) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_DEVICE_BY_ID_REQUEST
        })
        updateDeviceDetailAPI(data).then(res => {
            dispatch({
                type: UPDATE_DEVICE_BY_ID_SUCCESS,
                // payload: res && res.info
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_DEVICE_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const UPDATE_DEVICE_INSTALLATION_DATE_REQUEST = 'UPDATE_DEVICE_INSTALLATION_DATE_REQUEST';
export const UPDATE_DEVICE_INSTALLATION_DATE_SUCCESS = 'UPDATE_DEVICE_INSTALLATION_DATE_SUCCESS';
export const UPDATE_DEVICE_INSTALLATION_DATE_FAILED = 'UPDATE_DEVICE_INSTALLATION_DATE_FAILED';

export const updateDeviceInstallationDate = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_DEVICE_INSTALLATION_DATE_REQUEST
        })
        updateDeviceInstallationDateAPI(data).then(res => {
            dispatch({
                type: UPDATE_DEVICE_INSTALLATION_DATE_SUCCESS,
                payload: res && res.info
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_DEVICE_INSTALLATION_DATE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_IMPULSE_RATE_HISTORY_REQUEST = 'GET_IMPULSE_RATE_HISTORY_REQUEST';
export const GET_IMPULSE_RATE_HISTORY_SUCCESS = 'GET_IMPULSE_RATE_HISTORY_SUCCESS';
export const GET_IMPULSE_RATE_HISTORY_FAILED = 'GET_IMPULSE_RATE_HISTORY_FAILED';

export const getImpulseRateHistory = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_IMPULSE_RATE_HISTORY_REQUEST
        })
        getImpulseRateHistoryAPI(data).then(res => {
            dispatch({
                type: GET_IMPULSE_RATE_HISTORY_SUCCESS,
                payload: res
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_IMPULSE_RATE_HISTORY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_FIRMWARE_HISTORY_REQUEST = 'GET_FIRMWARE_HISTORY_REQUEST';
export const GET_FIRMWARE_HISTORY_SUCCESS = 'GET_FIRMWARE_HISTORY_SUCCESS';
export const GET_FIRMWARE_HISTORY_FAILED = 'GET_FIRMWARE_HISTORY_FAILED';

export const getFirmwareHistory = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_FIRMWARE_HISTORY_REQUEST
        })
        getFirmwareHistoryAPI(data).then(res => {
            // const { info = [] } = res || {}
            dispatch({
                type: GET_FIRMWARE_HISTORY_SUCCESS,
                payload: res
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_FIRMWARE_HISTORY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_DEVICE_HISTORY_REQUEST = 'GET_DEVICE_HISTORY_REQUEST';
export const GET_DEVICE_HISTORY_SUCCESS = 'GET_DEVICE_HISTORY_SUCCESS';
export const GET_DEVICE_HISTORY_FAILED = 'GET_DEVICE_HISTORY_FAILED';

export const getDeviceHistoryBySerialNo = (serialNo) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DEVICE_HISTORY_REQUEST
        })
        getDeviceHistoryBySerialNoAPI(serialNo).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: GET_DEVICE_HISTORY_SUCCESS,
                payload: info
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DEVICE_HISTORY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const UPDATE_DEVICE_HISTORY_TARIFF_RATES_REQUEST = 'UPDATE_DEVICE_HISTORY_TARIFF_RATES_REQUEST';
export const UPDATE_DEVICE_HISTORY_TARIFF_RATES_SUCCESS = 'UPDATE_DEVICE_HISTORY_TARIFF_RATES_SUCCESS';
export const UPDATE_DEVICE_HISTORY_TARIFF_RATES_FAILED = 'UPDATE_DEVICE_HISTORY_TARIFF_RATES_FAILED';

export const updateDeviceHistoryTariffRates = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_DEVICE_HISTORY_TARIFF_RATES_REQUEST
        })
        updateDeviceHistoryTariffRatesAPI(data).then(res => {
            dispatch({
                type: UPDATE_DEVICE_HISTORY_TARIFF_RATES_SUCCESS,
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_DEVICE_HISTORY_TARIFF_RATES_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const DownloadCSVAllDevices = () => dispatch => {
    return new Promise((resolve, reject) => {
        DownloadCSVAllDevicesAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const DownloadCSVDeviceFlash = (date, id) => dispatch => {
    return new Promise((resolve, reject) => {
        downloadCSVDeviceFlashAPI(date, id).then(res => {
            return resolve(res)
        }).catch(error => {
            // dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            dispatch(commonActions.openSnackbarsState({ message: error && error.response && error.response.data && error.response.data.message, messageType: 'error' }))
            return reject(error)
        })
    })
}


export const CLEAR_ALL_DEVICE_DATA = 'CLEAR_ALL_DEVICE_DATA';

export const clearStoreForDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_DEVICE_DATA
        })
    }
}

