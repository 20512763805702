import Validator from 'is_js'
import { isEmpty } from "lodash";

export function editInstallerAgent(data) {

    let errors = {};
    // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const emailRegex = /^.+@.+\..+$/

    // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/


    if (!data.agency_id || Validator.empty(data.agency_id)) {
        errors.agency_id = "Agency is required";
    }

    if (!data.first_name || Validator.empty(data.first_name)) {
        errors.first_name = "First Name is required";
    }

    if (data.first_name && data.first_name.length > 200) {
        errors.first_name = "First Name should be less than 200 character";
    }

    if (!data.last_name || Validator.empty(data.last_name)) {
        errors.last_name = "Last Name is required";
    }

    if (data.last_name && data.last_name.length > 200) {
        errors.last_name = "Last Name should be less than 200 character";
    }

    if (Validator.empty(data.email)) {
        errors.email = "Email is required";
    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"
    }

    if (Validator.empty(data.phone) || data.phone === undefined) {
        errors.phone = "Phone Number is required";
    }

    if (data.phone && (data.phone.length < 8)) {
        errors.phone = "Phone Number must be 8 to 13 characters";
    }

    if (data.phone && (data.phone.length > 13)) {
        errors.phone = "Phone Number must be 8 to 13 characters";
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}