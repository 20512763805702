import { isEmpty } from "lodash";

export function addJob(data) {
    let errors = {}

    if (!data?.job_name) {
        errors.job_name = 'Job Name is required.'
    }
    if (!data?.tariff?.id) {
        errors.tariff = 'Tariff  is required.'
    }

    if (!data?.tariff_rates?.id) {
        errors.tariff_rates = 'Tariff Rate is required.'
    }
    if (!data?.site?.id) {
        errors.site = 'Site is required.'
    }
    if (!data?.installer?.id) {
        errors.installer = 'Installer is required.'
    }
    return {
        isValid: isEmpty(errors),
        errors
    }
}